class DosageCalculator {

  static bsa = function (patient_data) {
    return 0.007184 * Math.pow(parseFloat(patient_data.last_bmi_data.length), 0.725) * Math.pow(parseFloat(patient_data.last_bmi_data.weight), 0.425);
  };

  static gfr = function (patient_data) {
    // if male
    if (patient_data.sex === '0') {
      let res = (((140 - parseFloat(patient_data.age)) * parseFloat(patient_data.last_bmi_data.weight)) / (72 * parseFloat(patient_data.last_laboratory['2'].val)));
      return res < 125 ? res : 125;
    } else { // if female
      let res = (((140 - parseFloat(patient_data.age)) * parseFloat(patient_data.last_bmi_data.weight)) / (72 * parseFloat(patient_data.last_laboratory['2'].val))) * 0.85;
      return res < 125 ? res : 125;
    }
  };

  static unit_list = {
    'mg_m2': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.bsa) * parseFloat(dosage);
    },
    'mcg_m2': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.bsa) * parseFloat(dosage);
    },
    'unit_m2': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.bsa) * parseFloat(dosage);
    },
    'mu_m2': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.bsa) * parseFloat(dosage);
    },
    'auc': function (self, patient_data, dosage) {
      // return (self.gfr(patient_data) + 25) * dosage;
      let gfr = parseInt(patient_data.last_laboratory['2'].gfr) >= 125 ? 125 : parseInt(patient_data.last_laboratory['2'].gfr);
      return (gfr + 25) * parseFloat(dosage);
    },
    'mg_kg': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.weight) * parseFloat(dosage);
    },
    'mcg_kg': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.weight) * parseFloat(dosage);
    },
    'mu_kg': function (self, patient_data, dosage) {
      return parseFloat(patient_data.last_bmi_data.weight) * parseFloat(dosage);
    }
  };

  static calculate = function (unit_value, patient_data, dosage) {
    if (unit_value in this.unit_list) {
      if (['mg_m2', 'mcg_m2', 'unit_m2'].indexOf(unit_value) !== -1 && !patient_data.last_bmi_data.bsa) {
        return '';
      }
      if (['auc'].indexOf(unit_value) !== -1) {
        if (!patient_data.last_laboratory['2'] || patient_data.last_laboratory['2'].gfr === 'none') {
          return '';
        }
      }
      let calculated_dosage = this.unit_list[unit_value](this, patient_data, parseFloat(dosage));
      if (calculated_dosage > 30) {
        return Math.round(calculated_dosage);
      } else {
        return calculated_dosage.toFixed(1);
      }
    } else {
      return dosage;
    }
  };

};

class ListingNumbers {
  static calculate = function (list, type) {
    if (type === 'onebyone') {
      list.sort(function (a, b) { return a - b; });
      return list.join(',');
    } else { // 1-4. günlerde şeklinde düzenliyor
      let day = '';
      list.sort(function (a, b) { return a - b; });
      if (list.length === 1) {
        day = list[0].toString();
      } else if (list.length === 2) {
        day = list.join(',');
      } else if (list.length >= 3) {
        for (let i in list) {
          i = parseInt(i);
          if (i === 0) {
            day += list[0].toString(); // ilk değeri yazıyoruz.
          } else if (i === list.length - 1) { // son değeri yazıyoruz.
            if (list[i] - 1 !== list[i - 1]) {
              day += ',' + list[i].toString();
            } else if (list[i] - 1 === list[i - 1]) {
              day += '-' + list[i].toString();
            }
          } else { // aradaki değerler için
            if (list[i] - 1 !== list[i - 1]) {
              day += ',' + list[i].toString();
            } else if ((list[i] - 1 === list[i - 1]) && (list[i] + 1 !== list[i + 1])) {
              day += '-' + list[i].toString();
            }
          }
        }
      }
      return day;
    }
  };
};

class GeneralFunc {
  static caption_to_text = function (options) {
    let new_options = [];
    for (let i in options) {
      if (options[i].label) {
        new_options.push({'text': options[i].label, 'value': options[i]});
      }
    }
    return new_options;
  };
};

class RuleEngine {
  static field_tree_calculate = function (query_list) {
    for (let q in query_list) {
      let query = query_list[q];
      query.field_tree = {};
      for (let f in query.fields) {
        let loc = query.fields[f].loc;
        if (loc.pg.value === 'anatomy') {
          if (!query.field_tree.anatomy) { query.field_tree.anatomy = {}; }
          if (loc.anatomy_use === 'any') {
            if (!query.field_tree.anatomy['any']) { query.field_tree.anatomy['any'] = { 'name': {}, 'field_types': {} }; }
            this.create_loc_level(query.field_tree.anatomy.any, loc, f);
          } else if (loc.anatomy_use === 'parent') {
            if (!query.field_tree.anatomy['parent']) { query.field_tree.anatomy['parent'] = {}; }
            if (!query.field_tree.anatomy.parent[loc.anatomy_parent.value]) {
              query.field_tree.anatomy.parent[loc.anatomy_parent.value] = { 'name': loc.anatomy_parent, 'field_types': {} };
            }
            this.create_loc_level(query.field_tree.anatomy.parent[loc.anatomy_parent.value], loc, f);
          } else if (loc.anatomy_use === 'this') {
            if (!query.field_tree.anatomy['this']) { query.field_tree.anatomy['this'] = {}; }
            if (!query.field_tree.anatomy['this'][loc.anatomy.value]) {
              query.field_tree.anatomy['this'][loc.anatomy.value] = { 'name': loc.anatomy, 'field_types': {} };
            }
            this.create_loc_level(query.field_tree.anatomy['this'][loc.anatomy.value], loc, f);
          }
        } else {
          if (!query.field_tree[loc.pg.value]) { query.field_tree[loc.pg.value] = { 'name': loc.pg, 'field_types': {} }; }
          this.create_loc_level(query.field_tree[loc.pg.value], loc, f);
        }
      }
    }
  };
  static create_loc_level = function (field_data_model, loc, field_index) {
    /*
    Örnek field_tree data modeli
    let field_data_model = { 'name': {}, 'field_types': { 'not_this_list': { 'fields': [] }, 'this_list2': { 'list_parameter_label_1_level': { 'name': {}, 'fields': [], 'third_level': { 'this_list': { 'list_parameter_label_2_level': { 'name': {}, 'fields': [] } }, 'detail_list': { 'list_parameter_label_2_level': { 'name': {}, 'fields': [] } }, 'any_list': { 'list_parameter_label_2_level': { 'name': {}, 'fields': [] } } } } }, 'this_list3': { 'list_parameter_label_2_level': { 'name': {}, 'fields': [] } } } };
    */
    if (loc.level === '1') {
      if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
      field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
    } else if (loc.level === '2') {
      if (loc.pr2_in === 'list') {
        if (loc.list_use_1 === 'this') {
          if (!field_data_model.field_types['this_list2']) { field_data_model.field_types['this_list2'] = {}; }
          if (!field_data_model.field_types['this_list2'][loc.pr1.value]) { field_data_model.field_types['this_list2'][loc.pr1.value] = { 'name': loc.pr1, 'fields': [] }; }
          field_data_model.field_types['this_list2'][loc.pr1.value].fields.push(parseInt(field_index));
        } else if (loc.list_use_1 === 'any') {
          if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
          field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
        }
      } else if (loc.pr2_in === 'detail') {
        if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
        field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
      }
    } else if (loc.level === '3') {
      if (loc.pr2_in === 'list') {
        if (loc.pr3_in === 'list') {
          if (loc.list_use_1 === 'this') {
            if (loc.list_use_2 === 'this') {
              if (!field_data_model.field_types['this_list2']) { field_data_model.field_types['this_list2'] = {}; }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]) { field_data_model.field_types['this_list2'][loc.pr1.value] = { 'name': loc.pr1, 'fields': [] }; }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']) {
                field_data_model.field_types['this_list2'][loc.pr1.value]['third_level'] = {};
              }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['this_list']) {
                field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['this_list'] = {};
              }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['this_list'][loc.pr2.value]) {
                field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['this_list'][loc.pr2.value] = { 'name': loc.pr2, 'fields': [] };
              }
              field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['this_list'][loc.pr2.value].fields.push(parseInt(field_index));
            } else if (loc.list_use_2 === 'any') {
              if (!field_data_model.field_types['this_list2']) { field_data_model.field_types['this_list2'] = {}; }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]) { field_data_model.field_types['this_list2'][loc.pr1.value] = { 'name': loc.pr1, 'fields': [] }; }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']) {
                field_data_model.field_types['this_list2'][loc.pr1.value]['third_level'] = {};
              }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['any_list']) {
                field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['any_list'] = {};
              }
              if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['any_list'][loc.pr2.value]) {
                field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['any_list'][loc.pr2.value] = { 'name': loc.pr2, 'fields': [] };
              }
              field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['any_list'][loc.pr2.value].fields.push(parseInt(field_index));
            }
          } else if (loc.list_use_1 === 'any') {
            if (loc.list_use_2 === 'this') {
              if (!field_data_model.field_types['this_list3']) { field_data_model.field_types['this_list3'] = {}; }
              if (!field_data_model.field_types['this_list3'][loc.pr1.value]) { field_data_model.field_types['this_list3'][loc.pr1.value] = { 'name': loc.pr1, 'list': {} }; }
              if (!field_data_model.field_types['this_list3'][loc.pr1.value]['list'][loc.pr2.value]) { field_data_model.field_types['this_list3'][loc.pr1.value]['list'][loc.pr2.value] = { 'name': loc.pr2, 'fields': [] }; }
              field_data_model.field_types['this_list3'][loc.pr1.value]['list'][loc.pr2.value].fields.push(parseInt(field_index));
            } else if (loc.list_use_2 === 'any') {
              if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
              field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
            }
          }
        } else if (loc.pr3_in === 'detail') {
          if (loc.list_use_1 === 'this') {
            if (!field_data_model.field_types['this_list2']) { field_data_model.field_types['this_list2'] = {}; }
            if (!field_data_model.field_types['this_list2'][loc.pr1.value]) { field_data_model.field_types['this_list2'][loc.pr1.value] = { 'name': loc.pr1, 'fields': [] }; }
            if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']) {
              field_data_model.field_types['this_list2'][loc.pr1.value]['third_level'] = {};
            }
            if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list']) {
              field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list'] = {};
            }
            if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list'][loc.pr2.value]) {
              field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list'][loc.pr2.value] = { 'name': loc.pr2, 'detail': {} };
            }
            if (!field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list'][loc.pr2.value]['detail'][loc.detail_pr2.value]) {
              field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list'][loc.pr2.value]['detail'][loc.detail_pr2.value] = { 'name': loc.detail_pr2, 'fields': [] };
            }
            field_data_model.field_types['this_list2'][loc.pr1.value]['third_level']['detail_list'][loc.pr2.value]['detail'][loc.detail_pr2.value].fields.push(parseInt(field_index));
          } else if (loc.list_use_1 === 'any') {
            if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
            field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
          }
        }
      } else if (loc.pr2_in === 'detail') {
        if (loc.pr3_in === 'list') {
          if (loc.list_use_2 === 'this') {
            if (!field_data_model.field_types['this_list3']) {
              field_data_model.field_types['this_list3'] = {};
            }
            if (!field_data_model.field_types['this_list3'][loc.pr1.value]) {
              field_data_model.field_types['this_list3'][loc.pr1.value] = { 'name': loc.pr1, 'detail': {} };
            }
            if (!field_data_model.field_types['this_list3'][loc.pr1.value]['detail'][loc.detail_pr1.value]) {
              field_data_model.field_types['this_list3'][loc.pr1.value]['detail'][loc.detail_pr1.value] = { 'name': loc.detail_pr1, 'list': {} };
            }
            if (!field_data_model.field_types['this_list3'][loc.pr1.value]['detail'][loc.detail_pr1.value]['list'][loc.pr2.value]) {
              field_data_model.field_types['this_list3'][loc.pr1.value]['detail'][loc.detail_pr1.value]['list'][loc.pr2.value] = { 'name': loc.pr2, 'fields': [] };
            }
            field_data_model.field_types['this_list3'][loc.pr1.value]['detail'][loc.detail_pr1.value]['list'][loc.pr2.value].fields.push(parseInt(field_index));
          } else if (loc.list_use_2 === 'any') {
            if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
            field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
          }
        } else if (loc.pr3_in === 'detail') {
          if (!field_data_model.field_types['not_this_list']) { field_data_model.field_types['not_this_list'] = { 'fields': [] }; }
          field_data_model.field_types['not_this_list'].fields.push(parseInt(field_index));
        }
      }
    }
  };
};

class DateFunction {
  static date_dif_today = function (dt) {
    let date = new Date(dt);
    let dateMsec = date.getTime();
    let today = new Date();
    today.setHours(8, 0, 0, 0);
    let todayMsec = today.getTime();
    let msecPerDay = 86400000;
    let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
    return day_dif;
  };
};

class InputControl {
  static input_filter = function (event, type, my_list = []) {
    var key_list = [];
    if (my_list.length === 0) {
      if (['period', 'delay'].indexOf(type) !== -1) {
        key_list = ['ArrowUp', 'ArrowDown', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      }
    } else {
      key_list = my_list;
    }
    if (key_list.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  };
};

class if_buttons {
  static control = function (drug_key, cycle_index, day_index, type, prem_ind, prem_day, subject, dy_ind = false, treatment_data, user, selected_days = []) {
    // var user = JSON.parse(localStorage.getItem('user'));
    let prem_types = ['before', 'during', 'after'];
    if (treatment_data.status === 'terminated') { return false; }
    if (subject === 'days_approval_all_drugs') {
      // Bir önceki tedaviyi kontrol ediyoruz. Premedikasyonu önemsemiyoruz. Fakat rule managerla daha sonra bu konuda da uyarı vermemiz gerek. Yani bir sonraki siklusa geçince bir önceki siklusta almadığı tedaviler konusunda. Tedavi bittiyse yada tedavi iptal edildiyse diğer siklusta bu işlev gözükecek.
      let det = false;
      let is_there_drug = false;
      let is_there_prem = false;
      for (let drg_key in treatment_data.date_list[dy_ind]) {
        let drug_data = treatment_data.date_list[dy_ind][drg_key];
        // if (treatment_data.date_list[dy_ind][drg_key].name.value === 'radiotherapy') { return false; }
        if (treatment_data.date_list[dy_ind][drg_key].type === 'drug') { is_there_drug = true; }
        if (treatment_data.date_list[dy_ind][drg_key].before.length !== 0 || treatment_data.protocol_cycles.drugs[drg_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data.during.length !== 0 || treatment_data.date_list[dy_ind][drg_key].after.length !== 0) { is_there_prem = true; }
        if (det === true) {
          break;
        }
      }
      if (type === 'drug' && is_there_drug === false) { // ilaç bulunmuyorsa ilaç buton grubu görünmeyecek.
        return false;
      }
      if (['prem', 'before', 'during', 'after'].indexOf(type) !== -1 && is_there_prem === false) { // Hiçbir premedikasyon bulunmuyorsa ilaç buton grubu görünmeyecek.
        return false;
      }
    } else if (subject === 'doctor_approval') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['doctor', 'doctor_preparation', 'doctor_nurse'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_doctor-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'doctor_approval_all') {
      // console.log('selected_days', selected_days);
      let data = {};
      let day_data = {};
      if (selected_days.length === 0) {
        if (dy_ind !== 'none') {
          selected_days.push(dy_ind);
        }
      }
      let is_there = 0;
      for (let x in selected_days) {
        let day_ind = selected_days[x];
        day_data[day_ind] = treatment_data.date_list[day_ind];
        if (type === 'drug') {
          let is_radiotherapy = false;
          for (let drg_key in day_data[day_ind]) {
            is_there++;
            if (treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
              is_radiotherapy = true;
            }
            let cy = day_data[day_ind][drg_key].cycle_index;
            let dy = day_data[day_ind][drg_key].day_index;
            data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data.approvals;
            if (['doctor', 'doctor_preparation', 'doctor_nurse'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_doctor-approval-parent') === -1) {
              return false;
            }
          }
          if (is_there === 0 || (is_there === 1 && is_radiotherapy)) {
            return false;
          }
        } else { // before after during prem
          for (let drg_key in day_data[day_ind]) {
            for (let i in prem_types) {
              for (let prem in day_data[day_ind][drg_key][prem_types[i]]) {
                is_there++;
                let cy = day_data[day_ind][drg_key].cycle_index;
                let dy = day_data[day_ind][drg_key].day_index;
                let pr_dy = day_data[day_ind][drg_key][prem_types[i]][prem].approvals_index;
                let pr_ind = day_data[day_ind][drg_key][prem_types[i]][prem].prem_index;
                data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data[prem_types[i]][pr_ind].approvals[pr_dy];
                if (['doctor', 'doctor_preparation', 'doctor_nurse'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_doctor-approval-parent') === -1) {
                  return false;
                }
              }
            }
          }
        }
      }
      if (is_there === 0) { return false; }
    } else if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_6', 'doctor_7', 'doctor_8'].indexOf(subject) !== -1) {
      let drug_is = treatment_data.protocol_cycles.drugs[drug_key].drug.value;
      if (drug_is === 'radiotherapy') {
        return false;
      }
    } else if (subject === 'doctor_5') {
      let data = {};
      if (user.permissions_result.indexOf('oncology_treatment_doctor-approval-parent') === -1) {
        return false;
      }
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['doctor'].indexOf(data.drug_permission) !== -1 && ['doctor_3', 'doctor_4', 'doctor_7', 'doctor_8'].indexOf(data.status) === -1) {
        return false;
      }
    } else if (subject === 'doctor_5_all') {
      let day_data = {};
      day_data = treatment_data.date_list[dy_ind];
      if (type === 'drug') {
        for (let doctor_5_drug_key in day_data) {
          if (day_data[doctor_5_drug_key].type === 'drug') {
            let doctor_5_cycle_index = day_data[doctor_5_drug_key].cycle_index;
            let doctor_5_day_index = day_data[doctor_5_drug_key].day_index;
            let approval_data = treatment_data.protocol_cycles.drugs[doctor_5_drug_key].cycles[doctor_5_cycle_index].days[doctor_5_day_index].data.approvals;
            if (['doctor_preparation', 'doctor_nurse'].indexOf(approval_data.drug_permission) !== -1 || (['doctor'].indexOf(approval_data.drug_permission) !== -1 && ['doctor_3', 'doctor_4', 'doctor_7', 'doctor_8'].indexOf(approval_data.status) !== -1)) {
              return true;
            }
          }
        }
        return false;
      } else {
        for (let doctor_5_drug_key in day_data) {
          for (let i in prem_types) {
            for (let doctor_5_pr in day_data[doctor_5_drug_key][prem_types[i]]) {
              let doctor_5_cycle_index = day_data[doctor_5_drug_key].cycle_index;
              let doctor_5_day_index = day_data[doctor_5_drug_key].day_index;
              let doctor_5_day_prem_index = day_data[doctor_5_drug_key][prem_types[i]][doctor_5_pr].prem_index;
              let doctor_5_day = day_data[doctor_5_drug_key][prem_types[i]][doctor_5_pr].approvals_index;
              let approval_data = treatment_data.protocol_cycles.drugs[doctor_5_drug_key].cycles[doctor_5_cycle_index].days[doctor_5_day_index].data[prem_types[i]][doctor_5_day_prem_index].approvals[doctor_5_day];
              if (['doctor_preparation', 'doctor_nurse'].indexOf(approval_data.drug_permission) !== -1 || (['doctor'].indexOf(approval_data.drug_permission) !== -1 && ['doctor_3', 'doctor_4', 'doctor_7', 'doctor_8'].indexOf(approval_data.status) !== -1)) {
                return true;
              }
            }
          }
        }
        return false;
      }
    } else if (subject === 'doctor_9') {
      let drug_is = treatment_data.protocol_cycles.drugs[drug_key].drug.value;
      if (drug_is !== 'radiotherapy') {
        return false;
      }
    } else if (subject === 'nurse_approval') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['doctor_nurse', 'nurse_pharmacy', 'doctor_nurse'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_nurse-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'nurse_approval_all') {
      // gün içerisinde uygun bir adet durum saptanırsa bile ilgili alan açık görünecek ve sadece o ilaca işlem yaptırılacak arka planda.
      let data = {};
      let day_data = {};
      // let permission_1 = '';
      // let permission_2 = '';
      day_data[dy_ind] = treatment_data.date_list[dy_ind];
      if (type === 'drug') {
        let is_there = 0;
        let is_radiotherapy = false;
        for (let drg_key in day_data[dy_ind]) {
          is_there++;
          if (treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
            is_radiotherapy = true;
          }
          let cy = day_data[dy_ind][drg_key].cycle_index;
          let dy = day_data[dy_ind][drg_key].day_index;
          data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data.approvals;
          if (['doctor_nurse', 'nurse_pharmacy'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_nurse-approval-parent') === -1) {
            if (treatment_data.protocol_cycles.drugs[drg_key].drug.value !== 'radiotherapy') {
              return false;
            }
          }
          /*            permission_1 = data.drug_permission;
                      if (['doctor_nurse', 'nurse_pharmacy'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('nurse_approval') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                        return false;
                      }
                      permission_2 = permission_1; */
        }
        if (is_there === 0 || (is_there === 1 && is_radiotherapy)) {
          return false;
        }
      } else {
        let det = false;
        let is_there = 0;
        for (let drg_key in day_data[dy_ind]) {
          for (let i in prem_types) {
            for (let prem in day_data[dy_ind][drg_key][prem_types[i]]) {
              is_there++;
              let cy = day_data[dy_ind][drg_key].cycle_index;
              let dy = day_data[dy_ind][drg_key].day_index;
              let pr_dy = day_data[dy_ind][drg_key][prem_types[i]][prem].approvals_index;
              let pr_ind = day_data[dy_ind][drg_key][prem_types[i]][prem].prem_index;
              data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data[prem_types[i]][pr_ind].approvals[pr_dy];
              if (['doctor_nurse', 'nurse_pharmacy'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_nurse-approval-parent') === -1) {
                return false;
              }
              /*                permission_1 = data.drug_permission;
                              if (['doctor_nurse', 'nurse_pharmacy'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('nurse_approval') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                                return false;
                              }
                              permission_2 = permission_1; */
            }
            if (det === true) {
              break;
            }
          }
          if (det === true) {
            break;
          }
        }
        if (is_there === 0) {
          return false;
        }
      }
    } else if (subject === 'nurse_cancel') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (data.drug_permission !== 'nurse_pharmacy' || user.permissions_result.indexOf('oncology_treatment_nurse-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'pharmacy_approval') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['nurse_pharmacy', 'pharmacy_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'pharmacy_approval_all') { // gün içerisinde uygun bir adet durum saptanırsa bile ilgili alan açık görünecek ve sadece o ilaca işlem yaptırılacak arka planda.
      let data = {};
      let day_data = {};
      /*        let permission_1 = '';
              let permission_2 = ''; */
      day_data[dy_ind] = treatment_data.date_list[dy_ind];
      if (type === 'drug') {
        let is_there = 0;
        let is_radiotherapy = false;
        for (let drg_key in day_data[dy_ind]) {
          is_there++;
          if (treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
            is_radiotherapy = true;
          }
          let cy = day_data[dy_ind][drg_key].cycle_index;
          let dy = day_data[dy_ind][drg_key].day_index;
          data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data.approvals;
          if (['nurse_pharmacy', 'pharmacy_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-parent') === -1) {
            if (treatment_data.protocol_cycles.drugs[drg_key].drug.value !== 'radiotherapy') {
              return false;
            }
          }

          /*            permission_1 = data.drug_permission;
                      if (['nurse_pharmacy', 'pharmacy_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('pharmacy_actions') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                        return false;
                      }
                      permission_2 = permission_1; */
        }
        if (is_there === 0 || (is_there === 1 && is_radiotherapy)) {
          return false;
        }
      } else {
        let det = false;
        let is_there = 0;
        for (let drg_key in day_data[dy_ind]) {
          for (let i in prem_types) {
            for (let prem in day_data[dy_ind][drg_key][prem_types[i]]) {
              is_there++;
              let cy = day_data[dy_ind][drg_key].cycle_index;
              let dy = day_data[dy_ind][drg_key].day_index;
              let pr_dy = day_data[dy_ind][drg_key][prem_types[i]][prem].approvals_index;
              let pr_ind = day_data[dy_ind][drg_key][prem_types[i]][prem].prem_index;
              data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data[prem_types[i]][pr_ind].approvals[pr_dy];
              if (['nurse_pharmacy', 'pharmacy_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-parent') === -1) {
                return false;
              }
              /*                permission_1 = data.drug_permission;
                              if (['nurse_pharmacy', 'pharmacy_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('pharmacy_actions') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                                return false;
                              }
                              permission_2 = permission_1; */
            }
            if (det === true) {
              break;
            }
          }
          if (det === true) {
            break;
          }
        }
        if (is_there === 0) {
          return false;
        }
      }
    } else if (subject === 'preparation_approval') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['pharmacy_preparation', 'preparation_match', 'doctor_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_preparation-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'preparation_approval_all') { // gün içerisinde uygun bir adet durum saptanırsa bile ilgili alan açık görünecek ve sadece o ilaca işlem yaptırılacak arka planda.
      let data = {};
      let day_data = {};
      /*        let permission_1 = '';
              let permission_2 = ''; */
      day_data[dy_ind] = treatment_data.date_list[dy_ind];
      if (type === 'drug') {
        let is_there = 0;
        let is_radiotherapy = false;
        for (let drg_key in day_data[dy_ind]) {
          is_there++;
          if (treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
            is_radiotherapy = true;
          }
          let cy = day_data[dy_ind][drg_key].cycle_index;
          let dy = day_data[dy_ind][drg_key].day_index;
          data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data.approvals;
          if (['pharmacy_preparation', 'preparation_match', 'doctor_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_preparation-approval-parent') === -1) {
            if (treatment_data.protocol_cycles.drugs[drg_key].drug.value !== 'radiotherapy') {
              return false;
            }
          }
          /*            permission_1 = data.drug_permission;
                      if (['pharmacy_preparation', 'preparation_match', 'doctor_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('chemo_drug_preparation') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                        return false;
                      }
                      permission_2 = permission_1; */
        }
        if (is_there === 0 || (is_there === 1 && is_radiotherapy)) {
          return false;
        }
      } else {
        let det = false;
        let is_there = 0;
        for (let drg_key in day_data[dy_ind]) {
          for (let i in prem_types) {
            for (let prem in day_data[dy_ind][drg_key][prem_types[i]]) {
              is_there++;
              let cy = day_data[dy_ind][drg_key].cycle_index;
              let dy = day_data[dy_ind][drg_key].day_index;
              let pr_dy = day_data[dy_ind][drg_key][prem_types[i]][prem].approvals_index;
              let pr_ind = day_data[dy_ind][drg_key][prem_types[i]][prem].prem_index;
              data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data[prem_types[i]][pr_ind].approvals[pr_dy];
              if (['pharmacy_preparation', 'preparation_match', 'doctor_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_preparation-approval-parent') === -1) {
                return false;
              }
              /*                permission_1 = data.drug_permission;
                              if (['pharmacy_preparation', 'preparation_match', 'doctor_preparation'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('chemo_drug_preparation') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                                return false;
                              }
                              permission_2 = permission_1; */
            }
            if (det === true) {
              break;
            }
          }
          if (det === true) {
            break;
          }
        }
        if (is_there === 0) {
          return false;
        }
      }
    } else if (subject === 'match_approval') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['preparation_match', 'match_administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_match-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'match_approval_all') { // gün içerisinde uygun bir adet durum saptanırsa bile ilgili alan açık görünecek ve sadece o ilaca işlem yaptırılacak arka planda.
      let data = {};
      let day_data = {};
      /*        let permission_1 = '';
              let permission_2 = ''; */
      day_data[dy_ind] = treatment_data.date_list[dy_ind];
      if (type === 'drug') {
        let is_there = 0;
        let is_radiotherapy = false;
        for (let drg_key in day_data[dy_ind]) {
          is_there++;
          if (treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
            is_radiotherapy = true;
          }
          let cy = day_data[dy_ind][drg_key].cycle_index;
          let dy = day_data[dy_ind][drg_key].day_index;
          data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data.approvals;
          if (['preparation_match', 'match_administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_match-approval-parent') === -1) {
            if (treatment_data.protocol_cycles.drugs[drg_key].drug.value !== 'radiotherapy') {
              return false;
            }
          }
          /*            permission_1 = data.drug_permission;
                      if (['preparation_match', 'match_administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('chemo_drug_match') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                        return false;
                      }
                      permission_2 = permission_1; */
        }
        if (is_there === 0 || (is_there === 1 && is_radiotherapy)) {
          return false;
        }
      } else {
        let det = false;
        let is_there = 0;
        for (let drg_key in day_data[dy_ind]) {
          for (let i in prem_types) {
            for (let prem in day_data[dy_ind][drg_key][prem_types[i]]) {
              is_there++;
              let cy = day_data[dy_ind][drg_key].cycle_index;
              let dy = day_data[dy_ind][drg_key].day_index;
              let pr_dy = day_data[dy_ind][drg_key][prem_types[i]][prem].approvals_index;
              let pr_ind = day_data[dy_ind][drg_key][prem_types[i]][prem].prem_index;
              data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data[prem_types[i]][pr_ind].approvals[pr_dy];
              if (['preparation_match', 'match_administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_match-approval-parent') === -1) {
                return false;
              }
              /*                permission_1 = data.drug_permission;
                              if (['preparation_match', 'match_administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('chemo_drug_match') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                                return false;
                              }
                              permission_2 = permission_1; */
            }
            if (det === true) {
              break;
            }
          }
          if (det === true) {
            break;
          }
        }
        if (is_there === 0) {
          return false;
        }
      }
    } else if (subject === 'administration_approval') {
      let data = {};
      if (type === 'drug') {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
      } else {
        data = treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
      }
      if (['match_administration', 'administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_administration-approval-parent') === -1) {
        return false;
      }
    } else if (subject === 'administration_approval_all') { // gün içerisinde uygun bir adet durum saptanırsa bile ilgili alan açık görünecek ve sadece o ilaca işlem yaptırılacak arka planda.
      let data = {};
      let day_data = {};
      /*        let permission_1 = '';
              let permission_2 = ''; */
      day_data[dy_ind] = treatment_data.date_list[dy_ind];
      if (type === 'drug') {
        let is_there = 0;
        let is_radiotherapy = false;
        for (let drg_key in day_data[dy_ind]) {
          is_there++;
          if (treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
            is_radiotherapy = true;
          }
          let cy = day_data[dy_ind][drg_key].cycle_index;
          let dy = day_data[dy_ind][drg_key].day_index;
          data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data.approvals;
          if (['match_administration', 'administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_administration-approval-parent') === -1) {
            if (treatment_data.protocol_cycles.drugs[drg_key].drug.value !== 'radiotherapy') {
              return false;
            }
          }
          /*            permission_1 = data.drug_permission;
                      if (['match_administration', 'administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('chemo_drug_administration') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                        return false;
                      }
                      permission_2 = permission_1; */
        }
        if (is_there === 0 || (is_there === 1 && is_radiotherapy)) {
          return false;
        }
      } else {
        let det = false;
        let is_there = 0;
        for (let drg_key in day_data[dy_ind]) {
          for (let i in prem_types) {
            for (let prem in day_data[dy_ind][drg_key][prem_types[i]]) {
              is_there++;
              let cy = day_data[dy_ind][drg_key].cycle_index;
              let dy = day_data[dy_ind][drg_key].day_index;
              let pr_dy = day_data[dy_ind][drg_key][prem_types[i]][prem].approvals_index;
              let pr_ind = day_data[dy_ind][drg_key][prem_types[i]][prem].prem_index;
              data = treatment_data.protocol_cycles.drugs[drg_key].cycles[cy].days[dy].data[prem_types[i]][pr_ind].approvals[pr_dy];
              if (['match_administration', 'administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('oncology_treatment_administration-approval-parent') === -1) {
                return false;
              }
              /*                permission_1 = data.drug_permission;
                              if (['match_administration', 'administration'].indexOf(data.drug_permission) === -1 || user.permissions_result.indexOf('chemo_drug_administration') === -1 || (permission_2 !== '' && permission_2 !== permission_1)) {
                                return false;
                              }
                              permission_2 = permission_1; */
            }
            if (det === true) {
              break;
            }
          }
          if (det === true) {
            break;
          }
        }
        if (is_there === 0) {
          return false;
        }
      }
    }
    return true;
  };
};

class WisdomRegex {
  static regex1 = function (value) {
    // general info //
    // this regex is doing some changes to 'value'
    // 'value' needs at least 1 char for beginning regex
    // regex info //
    // 'value' can contain only 'a-z 0-9 _ -' chars (lowercase letters, numbers, underscore, hypen)
    // 'value' cannot contain consecutive '-' and/or '_' chars
    // status info //
    // if 'value' is empty, then status is false
    // if 'value' is not empty but its last char is underscore or hypen, then status is false
    // else, then status is true
    // example i/o //
    // input: -____---ThiS is Jus--------------TT a ____'''2' new ---- ____ test ,.$.:---
    // output: {'value': 'thisisjus-tta_2new-test-', 'status': false}
    let status = false;
    if (value.length > 0) {
      value = value.toLowerCase();
      value = value.replace(/[^a-z0-9_-]+/ig, '');
      value = value.replace(/[-]+/ig, '-');
      value = value.replace(/[_]+/ig, '_');
      value = value.replace(/(-_)+/ig, '-');
      value = value.replace(/(_-)+/ig, '_');
      value = value.replace(/^[-_]/ig, '');
      if (['_', '-'].indexOf(value.charAt(value.length - 1)) === -1) {
        status = true;
      }
    }
    return {'value': value, 'status': status};
  };
  static regex2 = function (value) {
    // general info //
    // this regex is doing some changes to 'value'
    // 'value' needs at least 1 char for beginning regex
    // regex info //
    // 'value' cannot contain '[]{};' chars
    // input: asdasd[asdasdasd]asdasd]asdasd{asdasdasd}
    // output: {'value': 'asdasdasdasdasdasdasdasdasdasdasdasd', 'status': false}
    let status = false;
    if (value.length > 0) {
      value = value.replace(/[\[\]\{\}\;]+/ig, '');
      status = true;
    }
    return {'value': value, 'status': status};
  };
  static email = function (value) {
    // general info //
    // this regex is used for email validation
    // regex info //
 
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    let status = false;

    if (value.length > 0) {
      reg.test(value) ? status = true : status = false;
    }

    return {'value': value, 'status': status};
  };
  static password = function (value) {
    // general info //
    // this regex is used for email validation
    // regex info //
 
    let reg = /^[a-zA-Z0-9$@$!%*?&#^\-_. +]+$/;

    let status = false;

    if (value.length > 0) {
      reg.test(value) ? status = true : status = false;
    }

    return {'value': value, 'status': status};
  };
};

export { DosageCalculator, ListingNumbers, DateFunction, InputControl, if_buttons, RuleEngine, GeneralFunc, WisdomRegex };
